import React, { useState } from 'react';

import styled from 'styled-components/macro';

const Select = styled.select`
  background: black;
  color: white;
  width: 120px;
  height: 40px;
  padding: 8px;
  border-radius: 0 8px 8px 0;
  font-size: 16px;
  border: 1px solid white;
  margin: 0;
`

const Input = styled.input`
  background: black;
  color: white;
  width: 120px;
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px 0 0 8px;
  font-size: 16px;
  border: 1px solid white;
  margin: 0;
`

const Container = styled.div`
  margin: 20px;
  display: flex;
`

export default function TimeSelector(props) {
  const [ unit, setUnit ] = useState("hours");

  function displayTime(ms,timeUnit) {
    let result;

    switch(timeUnit) {
      case "seconds":
        result = ms/1000;
        break;
      case "minutes":
        result = ms/1000/60;
        break;
      case "hours":
        result = ms/1000/60/60;
        break;
      default:
        result = ms;
    }

    return result;
  }

  function handleChange(newVal) {
    let ms;

    switch(unit) {
      case "seconds":
        ms = newVal * 1000;
        break;
      case "minutes":
        ms = newVal * 1000 * 60;
        break;
      case "hours":
        ms = newVal * 1000 * 60 * 60;
        break;
      default:
        ms = newVal;
    }

    props.onChange(ms);
  }

  return (
    <Container>
      <Input type="number" value={ displayTime(props.ms,unit) } onChange={ (e) => handleChange(parseInt(e.target.value)) } step={1} min={1} max={60}/>
      <Select name="unit" id="unit" value={unit} onChange={ (e) => setUnit(e.target.value)}>
        <option value="seconds">Seconds</option>
        <option value="minutes">Minutes</option>
        <option value="hours">Hours</option>
      </Select>
    </Container>
  );
}
