import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';

import gongSfx from 'sounds/gong.mp3';

import styled, { keyframes } from 'styled-components/macro';

const bounce = keyframes`
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-.8em); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-.1em); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
`

const DoneContainer = styled.div`
  font-size: 40px;
  background: crimson;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TickingContainer = styled.div`
  font-size: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const H1 = styled.h1`
  text-align: center;
`

const Bounce = styled.div`
  animation-name: ${ bounce };
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`

export default function Countdown(props) {
  const [ timesUp, setTimesUp ] = useState(false);
  const [play] = useSound(gongSfx);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimesUp(true);
    }, props.ms);
    return () => clearTimeout(timer);
  }, [props.ms]);

  useEffect(() => {
    timesUp && play();
  }, [timesUp, play]);

  // function renderTimesUp() {
  //   return (
  //     <DoneContainer onClick={() => alert("restarting...")}>
  //       <Bounce>
  //         <H1>⌛<br/>TIME'S UP!</H1>
  //       </Bounce>
  //     </DoneContainer>
  //   )
  // }

  return (
    timesUp ?
    <DoneContainer onClick={() => props.onClick()}>
      <Bounce>
        <H1>⌛<br/>TIME'S UP!</H1>
      </Bounce>
    </DoneContainer>
    :
    <TickingContainer>
      <Bounce>⏳</Bounce>
    </TickingContainer>
  );
}
