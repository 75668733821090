import React, { useState } from 'react';

import styled from 'styled-components/macro';
import TimeSelector from 'components/TimeSelector/TimeSelector';
import Countdown from 'components/Countdown/Countdown';

const App = styled.div`
  background-color: #282c34;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
`;

export default function AppComponent() {
  const [ start, setStart ] = useState(3600000);
  const [ end, setEnd ] = useState(7200000);
  const [ randomMs, setRandomMs ] = useState(4567890);
  const [ countdownStarted, setCountdownStarted ] = useState(false);

  function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function go() {
    setRandomMs(randomIntFromInterval(start,end));
    setCountdownStarted(true);
  }

  function restart() {
    setStart(3600000);
    setEnd(7200000);
    setCountdownStarted(false);
  }

  return (
    <App>
    {
      countdownStarted ?
      <Countdown ms={ randomMs } onClick={restart} /> :
      <>
        <h1 style={{textAlign: "center"}}>🥷<br/>
        Random Gong</h1>
        Between
        <TimeSelector ms={start} onChange={ setStart }/>
        and
        <TimeSelector ms={end} onChange={ setEnd }/>
        <button onClick={ () => go()}>GO!</button>
      </>
    }

    </App>
  );
}
